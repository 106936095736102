.global-auth-container {
    height: 100vh;
    background-image: url('../images/pumperpal-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    .auth-side-container {
        width: 450px;
        background-color: $headlineBlue;
        height: 100vh;
        position: relative;
        #authform {
            max-height: calc(100vh - 180px);
            overflow: auto;
        }
    }
    .primary-btn {
        padding: 10px !important;
        font-size: 16px;
    }
    .link-footer {
        position: absolute;
        bottom: 20px;
    }
}