.main {
  .statusBtn {
    padding: 6px 15px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    display: inherit;
    width: 100px;
    text-align: center;
  }
  .REQUESTED {
    color: $brick;
    background-color: $fadedPink;
  }
  .SCHEDULED {
    color: $green;
    background-color: $lightGreen;
  }
  .NO.ISSUE {
    color: $headlineBlue;
    background-color: $grey;
  }
  .CANCELLED {
    color: $brick;
    background-color: $fadedPink;
  }
  .primary-btn {
    font-size: 13px !important;
    padding: 6px 16px !important;
  }
}
