.primary-btn {
    &:not(.Mui-disabled) {
        background-color: $green !important;
    }
}
.edit-btn {
    &:not(.Mui-disabled) {
        background-color: #f6ccd1 !important;
        color: #d93447
    }
}
// .MuiSelect-select {
//     padding: 3px 30px 3px 10px !important;
//     border: none !important;
//     outline: none !important;
// }
.upload-doc-btn {
    border: 1px solid $green !important;
    width: 100%;
    background-color: $white !important;
    color: $green !important;
}