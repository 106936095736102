form, .form {
    .text-box {
        input, .MuiInputBase-formControl  {
            color: $white;
            border: none !important;
            background-color: $bgColor !important;
            outline: none !important;
        }
        svg {
            fill: $white !important;
        }
    }
}
.search-input {
    input {
        height: 30px;
        padding: 4px 0px;
    }
}