header {
  background-color: $white !important;
  box-shadow: none !important;
  border-bottom: 1px solid $grey;
  margin-bottom: 20px;
  button {
    color: $textColor !important;
    text-transform: none !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    margin: 0px 25px !important;
    &.active {
      border-bottom: 4px solid $starYellow;
    }
    &:hover {
        background-color: $white !important;
    }
  }
  .profile-name {
    width: 22px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: $textColor !important;
    font-family: SenBold !important;
    cursor: pointer;
    background-color: $grey;
    border-radius: 50%;
    padding: 10px;
  }
}