.loader {
    position: fixed;
    width: 100%;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    opacity: 0.8;
    z-index: 1100;
}
.title {
    font-size: 24px;
    font-family: SenBold !important;
    color: $textColor !important;
}
.upload-file {
    height: 60px;
    border: 1px dotted $darkGrey;
    cursor: pointer;
}
// Modal css
.css-1dcqlsp {
    max-height: calc(100vh - 100px);
    overflow: auto;
  }
  
  .css-1dcqlsp::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .css-1dcqlsp.scrollable-scrollbar::-webkit-scrollbar {
    opacity: 1;
  }
  
  .css-1dcqlsp::-webkit-scrollbar-thumb {
    background-color: #1A7E36; /* Color of the thumb */
    border-radius: 6px; /* Rounded corners of the thumb */
  }
  
  .css-1dcqlsp::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Color of the track */
    border-radius: 8px; /* Rounded corners of the track */
  }
  
.sort-icon-up {
    position: absolute;
    top: 6px;
}

.sort-icon-down {
    position: absolute;
    bottom: 8px;
}
.profile-image {
    border-radius: 50%;
}
.profile-pic-table {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.css-huadge .css-acctgf-MuiGrid-root {
    max-height: calc(100vh - 100px);
    overflow: auto;
  }
  
  .css-huadge .css-acctgf-MuiGrid-root::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .css-huadge .css-acctgf-MuiGrid-root.scrollable-scrollbar::-webkit-scrollbar {
    opacity: 1;
  }
  
  .css-huadge .css-acctgf-MuiGrid-root::-webkit-scrollbar-thumb {
    background-color: #1A7E36; /* Color of the thumb */
    border-radius: 6px; /* Rounded corners of the thumb */
  }
  
  .css-huadge .css-acctgf-MuiGrid-root::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Color of the track */
    border-radius: 8px; /* Rounded corners of the track */
  }
  
.css-a9f3no,
.css-12habe4 {
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.css-a9f3no::-webkit-scrollbar,
.css-12habe4::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  opacity: 0;
  transition: opacity 0.5s ease;
}

.css-a9f3no.scrollable-scrollbar::-webkit-scrollbar,
.css-12habe4.scrollable-scrollbar::-webkit-scrollbar {
  opacity: 1;
}

.css-a9f3no::-webkit-scrollbar-thumb,
.css-12habe4::-webkit-scrollbar-thumb {
  background-color: #1A7E36; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners of the thumb */
}

.css-a9f3no::-webkit-scrollbar-track,
.css-12habe4::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Color of the track */
  border-radius: 8px; /* Rounded corners of the track */
}

.root-container {
    max-height: 100vh;
    overflow: auto;
  }
  
  .root-container::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .root-container.scrollable-scrollbar::-webkit-scrollbar {
    opacity: 1;
  }
  
  .root-container::-webkit-scrollbar-thumb {
    background-color: #1A7E36; /* Color of the thumb */
    border-radius: 6px; /* Rounded corners of the thumb */
  }
  
  .root-container::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Color of the track */
    border-radius: 8px; /* Rounded corners of the track */
  }
  