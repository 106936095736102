@import "./Styles/index";

@font-face {
  font-family: SenBold;
  src: url("./Styles/fonts/Sen-Bold.ttf");
}

@font-face {
  font-family: SenExtraBold;
  src: url("./Styles/fonts/Sen-ExtraBold.ttf");
}

@font-face {
  font-family: SenRegular;
  src: url("./Styles/fonts/Sen-Regular.ttf");
}

body {
  margin: 0px;
  font-family: SenRegular;
}
